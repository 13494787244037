import React from 'react';

const CurrencyDollarCircle = ({ fill = '#91918D' }) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
      <g clipPath="url(#clip0_1490_92533)">
        <path
          fill={fill}
          fillRule="evenodd"
          d="M10 2.5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zM.833 10a9.167 9.167 0 1118.334 0A9.167 9.167 0 01.833 10zM10 3.75c.46 0 .834.373.834.833V5h.138a2.778 2.778 0 012.778 2.778.833.833 0 01-1.666 0c0-.614-.498-1.111-1.112-1.111H9.167a1.25 1.25 0 000 2.5h1.667a2.917 2.917 0 010 5.833v.417a.833.833 0 01-1.667 0V15h-.14a2.778 2.778 0 01-2.777-2.778.833.833 0 011.667 0c0 .614.497 1.111 1.11 1.111h1.807a1.25 1.25 0 000-2.5H9.167a2.917 2.917 0 010-5.833v-.417c0-.46.373-.833.833-.833z"
          clipRule="evenodd"></path>
      </g>
      <defs>
        <clipPath id="clip0_1490_92533">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default CurrencyDollarCircle;
