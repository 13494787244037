import React from 'react';

const BarChart12 = ({ fill = '#91918D' }) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.67"
        d="M7.5 10H3.833c-.466 0-.7 0-.878.09a.833.833 0 00-.364.365c-.091.178-.091.412-.091.878v4.834c0 .466 0 .7.09.878.08.157.208.284.365.364.178.091.412.091.878.091H7.5m0 0h5m-5 0V7.167c0-.467 0-.7.09-.879a.833.833 0 01.365-.364c.178-.09.412-.09.878-.09h2.334c.466 0 .7 0 .878.09.157.08.284.208.364.364.091.179.091.412.091.879V17.5m0 0h3.667c.466 0 .7 0 .878-.09a.833.833 0 00.364-.365c.091-.178.091-.412.091-.878V3.833c0-.466 0-.7-.09-.878a.833.833 0 00-.365-.364c-.178-.091-.412-.091-.878-.091h-2.334c-.466 0-.7 0-.878.09a.833.833 0 00-.364.365c-.091.178-.091.412-.091.878v2.834"></path>
    </svg>
  );
};

export default BarChart12;
