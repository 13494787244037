import React from 'react';

const Users01 = ({ fill = '#91918D', width = '20', height = '20' }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 20 20">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M7.917 3.333a2.5 2.5 0 100 5 2.5 2.5 0 000-5zm-4.167 2.5a4.167 4.167 0 118.333 0 4.167 4.167 0 01-8.333 0zm8.394-3.403a.833.833 0 011.085-.46 4.168 4.168 0 010 7.727.833.833 0 01-.625-1.545 2.501 2.501 0 000-4.637.833.833 0 01-.46-1.085zm-5.507 9.237h2.56c.75 0 1.357 0 1.85.033.508.035.956.108 1.381.284a4.167 4.167 0 012.255 2.255c.176.425.249.873.284 1.38.033.494.033 1.1.033 1.852v.03a.833.833 0 01-1.666 0c0-.789-.001-1.338-.03-1.768-.03-.423-.083-.668-.16-.856a2.5 2.5 0 00-1.354-1.353c-.188-.078-.434-.132-.856-.16-.43-.03-.98-.03-1.767-.03h-2.5c-.788 0-1.337 0-1.767.03-.423.028-.669.082-.857.16a2.5 2.5 0 00-1.353 1.353c-.077.188-.131.433-.16.856-.03.43-.03.98-.03 1.767a.833.833 0 01-1.667 0v-.03c0-.751 0-1.357.034-1.85.035-.508.108-.956.284-1.381a4.167 4.167 0 012.255-2.255c.424-.176.873-.249 1.38-.284.494-.033 1.1-.033 1.851-.033zm8.39.73a.833.833 0 011.014-.599 4.168 4.168 0 013.126 4.035V17.5a.833.833 0 01-1.667 0v-1.667a2.502 2.502 0 00-1.874-2.42.833.833 0 01-.6-1.016z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default Users01;
