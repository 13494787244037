import {
  CLICKED_ANALYTICS_SIDENAV_EVENT,
  CLICKED_CHALLENGES_PAGE_SIDENAV_EVENT,
  CLICKED_EVENTS_SIDENAV_EVENT,
  CLICKED_GETTING_STARTED_SIDENAV_EVENT,
  CLICKED_HOME_SIDENAV_EVENT,
  CLICKED_LIBRARY_SIDENAV_EVENT,
  CLICKED_MAGIC_REACH_SIDENAV_EVENT,
  CLICKED_MEMBERS_SIDENAV_EVENT,
  CLICKED_MONEY_PAGE_SIDENAV_EVENT,
  CLICKED_SETTINGS_SIDENAV_EVENT
} from '@/utility/analyticsConsts';

import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import Users01 from '@/icons/collection/Users01';
import BarChart12 from '@/icons/collection/BarChart12';
import Calendar from '@/icons/collection/Calendar';
import HomeSmile from '@/icons/collection/HomeSmile';
import Rows01 from '@/icons/collection/Rows01';
import Settings01 from '@/icons/collection/Settings01';
import CurrencyDollarCircle from '@/icons/collection/CurrencyDollarCircle';
import Announcement03 from '@/icons/collection/Announcement03';
import SendMagic01 from '@/icons/collection/SendMagic01';
import {
  CM_PORTAL_ANALYTICS_PAGE_ROUTE,
  CM_PORTAL_CHALLENGES_PAGE_ROUTE,
  CM_PORTAL_EVENTS_ROUTE,
  CM_PORTAL_FEED_PAGE_ROUTE,
  CM_PORTAL_GETTING_STARTED_ROUTE,
  CM_PORTAL_HOMEPAGE_ROUTE,
  CM_PORTAL_HOME_ROUTE,
  CM_PORTAL_LIBRARY_PAGE_ROUTE,
  CM_PORTAL_MAGIC_REACH_PAGE_ROUTE,
  CM_PORTAL_MEMBERS_PAGE_ROUTE,
  CM_PORTAL_MONEY_PAGE_ROUTE,
  CM_PORTAL_PAID_ADS_PAGE_ROUTE,
  CM_PORTAL_PROMOTIONS_PAGE_ROUTE,
  CM_PORTAL_SETTINGS_PAGE_ROUTE
} from '@/utility/routesHelper';
import { t } from '@/utility/localization';
import Icon from '@/components/npl/Icon';
import { LOCALES } from '@/utility/localization/constants';

export const slideOutKeyframes = [
  { transform: 'translateX(100%)' },
  { transform: 'translateX(0)' }
];

export const slideInKeyframes = [
  { transform: 'translateX(0)' },
  { transform: 'translateX(100%)' }
];

export const slideAnimationConfig = {
  duration: 200,
  fill: 'forwards'
};

export default () => {
  const nasIoImgStoragePath = `${STATIC_ASSET_BASE_URL}/nasIO/portal`;

  const MEMBERS_PAGE = {
    name: 'MEMBERS_PAGE',
    label: t('members'),
    Icon: Users01,
    link: CM_PORTAL_MEMBERS_PAGE_ROUTE,
    alternateLink: CM_PORTAL_MEMBERS_PAGE_ROUTE,
    gtmAnalyticsActionId: CLICKED_MEMBERS_SIDENAV_EVENT,
    testId: 'members',
    relatedRoutes: [CM_PORTAL_ANALYTICS_PAGE_ROUTE],
    id: 'members'
  };
  const ANALYTIC_PAGE = {
    name: 'ANALYTIC_PAGE',
    label: t('analytics'),
    Icon: BarChart12,
    link: CM_PORTAL_ANALYTICS_PAGE_ROUTE,
    gtmAnalyticsActionId: CLICKED_ANALYTICS_SIDENAV_EVENT,
    testId: 'analytics',
    id: 'analytics'
  };
  const EVENTS_PAGE = {
    name: 'EVENTS_PAGE',
    label: t('events'),
    Icon: Calendar,
    link: CM_PORTAL_EVENTS_ROUTE,
    includeSubRoutes: true,
    gtmAnalyticsActionId: CLICKED_EVENTS_SIDENAV_EVENT,
    testId: 'events'
  };

  const GETTING_STARTED_PAGE = {
    name: 'GETTING_STARTED_PAGE',
    label: t('get-started'),
    link: CM_PORTAL_GETTING_STARTED_ROUTE,
    gtmAnalyticsActionId: CLICKED_GETTING_STARTED_SIDENAV_EVENT,
    testId: 'get-started'
  };

  const ANNOUNCEMENTS_PAGE = {
    name: 'ANNOUNCEMENTS_PAGE',
    label: t('home'),
    Icon: HomeSmile,
    link: CM_PORTAL_HOMEPAGE_ROUTE,
    alternateLink: CM_PORTAL_HOME_ROUTE,
    gtmAnalyticsActionId: CLICKED_HOME_SIDENAV_EVENT,
    testId: 'home'
  };

  const COMMUNICATE_PAGE = {
    name: 'COMMUNICATE_PAGE',
    label: t('magic-reach'),
    Icon: SendMagic01,
    link: CM_PORTAL_MAGIC_REACH_PAGE_ROUTE,
    gtmAnalyticsActionId: CLICKED_MAGIC_REACH_SIDENAV_EVENT,
    testId: 'magic-reach'
  };

  const LIBRARY_PAGE = {
    name: 'LIBRARY_PAGE',
    label: t('products'),
    Icon: Rows01,
    link: CM_PORTAL_LIBRARY_PAGE_ROUTE,
    includeSubRoutes: true,
    gtmAnalyticsActionId: CLICKED_LIBRARY_SIDENAV_EVENT,
    testId: 'library'
  };

  const SETTING_PAGE = {
    name: 'SETTING_PAGE',
    label: t('settings'),
    Icon: Settings01,
    link: CM_PORTAL_SETTINGS_PAGE_ROUTE,
    gtmAnalyticsActionId: CLICKED_SETTINGS_SIDENAV_EVENT,
    testId: 'settings'
  };

  const MONEY_PAGE = {
    name: 'MONEY_PAGE',
    label: t('money'),
    Icon: CurrencyDollarCircle,
    link: CM_PORTAL_MONEY_PAGE_ROUTE,
    gtmAnalyticsActionId: CLICKED_MONEY_PAGE_SIDENAV_EVENT,
    testId: 'money'
  };

  const FEED_PAGE = {
    name: 'FEED_PAGE',
    label: t('feed'),
    Icon: (props) => (
      <Icon name="distribute-spacing-vertical" {...props} />
    ),
    link: CM_PORTAL_FEED_PAGE_ROUTE,
    gtmAnalyticsActionId: CLICKED_MONEY_PAGE_SIDENAV_EVENT,
    testId: 'feed'
  };

  const CHALLENGES_PAGE = {
    name: 'CHALLENGES_PAGE',
    label: t('challenges'),
    Icon: (props) => <Icon name="trophy-01" {...props} />,
    link: CM_PORTAL_CHALLENGES_PAGE_ROUTE,
    gtmAnalyticsActionId: CLICKED_CHALLENGES_PAGE_SIDENAV_EVENT,
    testId: 'challenges',
    isNew: false
  };

  const PAID_ADS_PAGE = {
    name: 'PAID_ADS_PAGE',
    label: t('paid-ads'),
    Icon: Announcement03,
    link: CM_PORTAL_PAID_ADS_PAGE_ROUTE,
    gtmAnalyticsActionId: 'clicked_paid_ads_sidenav_event',
    testId: 'paid-ads',
    isNew: false
  };

  const PROMOTIONS_PAGE = {
    name: 'PROMOTIONS_PAGE',
    label: t('promotions'),
    Icon: (props) => <Icon name="sale-02" {...props} />,
    link: CM_PORTAL_PROMOTIONS_PAGE_ROUTE,
    gtmAnalyticsActionId: 'clicked_promotions_sidenav_event',
    testId: 'promotions',
    isNew: false
  };

  const TAB_PAGES = [MEMBERS_PAGE, ANALYTIC_PAGE];

  const groupedNavItems = [
    {
      items: [
        GETTING_STARTED_PAGE,
        ANNOUNCEMENTS_PAGE,
        MONEY_PAGE,
        MEMBERS_PAGE
      ]
    },
    {
      title: t('engage'),
      items: [CHALLENGES_PAGE, LIBRARY_PAGE, EVENTS_PAGE, FEED_PAGE]
    },
    {
      title: t('marketing'),
      items: [COMMUNICATE_PAGE, PROMOTIONS_PAGE, PAID_ADS_PAGE]
    },
    {
      title: t('manage'),
      items: [SETTING_PAGE]
    }
  ];

  const sampleProfileImg = {
    alt: 'profile image',
    desktopImgProps: {
      src: 'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage5.jpg',
      width: 40,
      height: 40
    },
    mobileImgProps: {
      src: 'https://d2yjtdaqamc55g.cloudfront.net/randomProfileImage5.jpg',
      width: 40,
      height: 40
    }
  };

  const ANNOUNCEMENT_MODAL_PROPS = {
    title: t(
      'level-up-your-monetization-potential-with-free-gift-from-nas-io'
    ),
    description: t('elevate-your-community-monetization'),
    imageProps: {
      alt: 'Gift promotion',
      mobileImgProps: {
        src: `${STATIC_ASSET_BASE_URL}/nasIO/common/png/firstdollar.png`,
        width: 500,
        height: 250
      }
    }
  };

  // For now adding new one for every campaign, should make this dynamic in the future so this modal is truely reusable
  const ANNOUNCEMENT_MODAL_DISPLAY = 'announcement-modal-display';
  const ANNOUNCEMENT_MODAL_DISPLAY_1_DOLLAR =
    'announcement-modal-display-1-dollar';

  const getProfileImageObject = (url) => {
    if (!url) {
      return sampleProfileImg;
    }
    return {
      alt: 'profile image',
      desktopImgProps: {
        src: url,
        width: 44,
        height: 44
      },
      mobileImgProps: {
        src: url,
        width: 44,
        height: 44
      }
    };
  };

  const engagementTabStaticProps = {
    connectStateImg: {
      alt: 'Engagement Image',
      mobileImgData: {
        src: `${nasIoImgStoragePath}/png/image 3.png`,
        meta: {
          width: 350,
          height: 350
        }
      }
    },
    waitingStateImg: {
      alt: 'Engagement Image',
      mobileImgData: {
        src: `${nasIoImgStoragePath}/png/waiting-state-two.png`,
        meta: {
          width: 350,
          height: 350
        }
      }
    },
    infoMessages: {
      chatNotApplied: {
        main: t(
          'connect-your-community-chat-to-start-measuring-your-members-engagement'
        )
      },
      chatApplied: (chat) => ({
        main: t('chat-connected', { chat }),
        subtext: t('preparing-data-for-analytics-please-check-back-later')
      })
    }
  };

  const magicReachEmptyStateProps = {
    title: t('communicate-with-your-audience-with-1-click'),
    description: t(
      'form-deeper-connections-by-sending-and-tracking-messages-to-your-community-members'
    ),
    imgProps: {
      alt: 'empty result',
      mobileImgProps: {
        src: `${STATIC_ASSET_BASE_URL}/nasIO/portal/png/empty-state-magic-reach.png`,
        width: 660,
        height: 220
      }
    },
    primaryCtaIcon: 'plus',
    primaryCtaLabel: t('new-message'),
    secondaryCtaLabel: t('learn-more'),
    secondaryCtaLink:
      'https://help.nas.io/en/collections/3910259-magic-reach'
  };
  const eventsEmptyStateProps = {
    title: t('live-events-increase-a-communitys-engagement-by-38'),
    description: t(
      'connect-and-grow-your-audience-through-weekly-meetups-or-webinars-with-guest-speakers'
    ),
    imgProps: {
      alt: 'empty result',
      mobileImgProps: {
        src: `${STATIC_ASSET_BASE_URL}/nasIO/portal/png/empty-state-event.png`,
        width: 660,
        height: 220
      }
    },
    primaryCtaLabel: t('create-event'),
    secondaryCtaLabel: t('learn-more'),
    secondaryCtaLink:
      'https://help.nas.io/en/collections/3688571-hosting-events'
  };

  const NASIO_HELP_URL = 'https://help.nas.io';

  const CHAT_TAB_ROUTE = '/portal/settings?tab=chat';

  const REFERRAL_NOTIFICATION_REMOVAL_KEY = 'referralNotificationRemoval';

  const NASIO_MANAGER_PORTAL_TEXT = t('nas-io-manager-portal');

  const EMPTY_PROFILE_IMAGE_PATH = `${STATIC_ASSET_BASE_URL}/nasIO/portal/png/empty-avatar.png`;

  const getMemberPortalNavigationItems = () => ({
    memberHomePage: {
      name: 'MEMBER_HOME_PAGE',
      label: t('home'),
      Icon: HomeSmile,
      link: '/member',
      alternateLink: '/member/feed',
      testId: 'member-home-age'
    },
    memberEventsPage: {
      name: 'MEMBER_EVENTS_PAGE',
      label: t('events'),
      Icon: Calendar,
      link: '/member/events',
      testId: 'member-events-page'
    },
    memberChallengePage: {
      name: 'MEMBER_CHALLEGE_PAGE',
      label: t('challenges'),
      Icon: (props) => <Icon name="trophy-01" {...props} />,
      link: '/member/challenges',
      testId: 'member-challenge-page'
    },
    memberContentPage: {
      name: 'MEMBER_CONTENT_PAGE',
      label: t('products'),
      Icon: Rows01,
      link: '/member/products',
      testId: 'member-content-page'
    },
    memberMembersPage: {
      name: 'MEMBER_MEMBERS_PAGE',
      label: t('members'),
      Icon: Users01,
      link: '/member/members',
      testId: 'member-members-page'
    },
    memberSettingsPage: {
      name: 'MEMBER_SETTINGS_PAGE',
      label: t('settings'),
      Icon: Settings01,
      link: '/member/settings',
      testId: 'member-settings-page'
    }
  });

  const getMemberPortalMenu = () => {
    const items = getMemberPortalNavigationItems();

    let visibleItems = [
      items.memberHomePage,
      items.memberEventsPage,
      items.memberContentPage,
      items.memberMembersPage,
      items.memberChallengePage
    ];

    return [
      {
        items: [...visibleItems]
      }
    ];
  };

  const NASIO_PRODUCT_WALKTHROUGH_VID_URL =
    'https://d2oi1rqwb0pj00.cloudfront.net/nasio/get-started/walkthrough/nasio-walkthrough-with-nuseir-sep-2024.mp4';
  const NASIO_PRODUCT_WALKTHROUGH_VID_JAPANESE_URL =
    'https://d2oi1rqwb0pj00.cloudfront.net/nasio/get-started/walkthrough/nasio-walkthrough-japanese-sep-2024.mp4';
  const NASIO_COMMUNITY_BUILDING_COURSE_VID_URL_ES = `${STATIC_ASSET_BASE_URL}/es/Walkthrough-Espanyol.mp4`;
  const NASIO_COMMUNITY_BUILDING_COURSE_VID_URL_PT_BR = `${STATIC_ASSET_BASE_URL}/nasIO/portuguese/mp4/WALKTHROUGH_PORTUGESE_COMPRESSED.mp4`;

  const getCommunityBuildingVidByLocale = (locale) => {
    try {
      switch (locale.toLowerCase()) {
        case LOCALES.SPANISH_MX:
          return NASIO_COMMUNITY_BUILDING_COURSE_VID_URL_ES;

        case LOCALES.PORTUGUESE_BR:
          return NASIO_COMMUNITY_BUILDING_COURSE_VID_URL_PT_BR;

        case LOCALES.JAPANESE:
          return NASIO_PRODUCT_WALKTHROUGH_VID_JAPANESE_URL;

        default:
          return NASIO_PRODUCT_WALKTHROUGH_VID_URL;
      }
    } catch (e) {
      return NASIO_PRODUCT_WALKTHROUGH_VID_URL;
    }
  };

  const memberSideBarTabs = [
    {
      id: 'details',
      label: t('details'),
      testId: 'details-tab'
    },
    {
      id: 'activity',
      label: t('activity'),
      testId: 'activity-tab'
    }
  ];

  return {
    memberSideBarTabs,
    EMPTY_PROFILE_IMAGE_PATH,
    NASIO_MANAGER_PORTAL_TEXT,
    REFERRAL_NOTIFICATION_REMOVAL_KEY,
    CHAT_TAB_ROUTE,
    NASIO_HELP_URL,
    slideOutKeyframes,
    slideInKeyframes,
    slideAnimationConfig,
    eventsEmptyStateProps,
    magicReachEmptyStateProps,
    engagementTabStaticProps,
    getProfileImageObject,
    sampleProfileImg,
    groupedNavItems,
    TAB_PAGES,
    LIBRARY_PAGE,
    MEMBERS_PAGE,
    MONEY_PAGE,
    SETTING_PAGE,
    GETTING_STARTED_PAGE,
    CHALLENGES_PAGE,
    getMemberPortalMenu,
    ANNOUNCEMENT_MODAL_PROPS,
    ANNOUNCEMENT_MODAL_DISPLAY,
    ANNOUNCEMENT_MODAL_DISPLAY_1_DOLLAR,
    getCommunityBuildingVidByLocale
  };
};

export const COMMUNITY_EXPIRED = 'Expired'; // Status, not a string
export const COMMUNITY_RENEWAL_AVAILABLE = 'Renewal_Available';
export const USER_SEEN_CAMPAIGN_ANNOUNCEMENTS =
  'seenCampaignAnnouncements';
