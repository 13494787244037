import React from 'react';

const Announcement03 = ({ fill = '#91918D', width = 20, height = 20 }) => {
  return (
    <svg width={width} height={height} fill="none" viewBox="0 0 20 20">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M14.088 4.482c-.45.836-.755 2.05-.755 3.435 0 1.385.304 2.598.755 3.435.336.624.692.943.996 1.074l.393.071c.348-.03.826-.323 1.268-1.145.45-.837.755-2.05.755-3.435 0-1.386-.304-2.599-.755-3.435-.442-.822-.92-1.116-1.268-1.146l-.393.072c-.304.13-.66.45-.996 1.074zm-1.561-.61c-.548 1.101-.86 2.523-.86 4.045 0 1.521.312 2.943.86 4.044l-7.842-1.426c-.826-.15-1.07-.202-1.253-.292a1.667 1.667 0 01-.869-1.04c-.055-.196-.063-.447-.063-1.286 0-.84.008-1.09.063-1.286.13-.454.445-.832.869-1.04.182-.09.427-.143 1.253-.293l7.842-1.426zm2.093-2.074L4.387 3.658l-.112.02c-.663.121-1.16.21-1.578.417A3.333 3.333 0 00.96 6.175c-.128.45-.127.954-.127 1.628v.228c0 .674 0 1.178.127 1.627.258.908.89 1.665 1.737 2.08.198.098.413.17.656.23l.312 4.378c.014.197.028.39.053.552.027.18.076.39.196.599.167.29.418.525.72.67.217.105.43.14.611.153.164.014.357.013.554.013h1.539c.226 0 .444 0 .628-.015.2-.017.438-.057.675-.184.325-.175.583-.454.732-.791a1.69 1.69 0 00.132-.688 7.945 7.945 0 00-.033-.627l-.228-2.97 5.376.978c.25.084.515.13.796.13 1.26 0 2.21-.934 2.796-2.024.606-1.124.954-2.62.954-4.225 0-1.606-.348-3.102-.954-4.226-.586-1.09-1.537-2.024-2.796-2.024a2.47 2.47 0 00-.796.13zM7.549 12.75l-2.502-.455.279 3.907a6.567 6.567 0 00.041.456l.01.001c.09.007.216.008.448.008H7.31c.264 0 .41 0 .516-.01h.012a7.131 7.131 0 00-.03-.528l-.26-3.38z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default Announcement03;
