import React from 'react';

const HomeSmile = ({ fill = '#91918D' }) => {
  return (
    <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M9.564 1.114c.286-.078.586-.078.871 0 .332.09.61.308.833.483l.062.048 5.652 4.396.065.05c.314.244.59.459.796.737.181.245.316.52.398.813.093.334.093.684.092 1.081v6.143c0 .44 0 .819-.025 1.13-.027.33-.087.658-.247.973a2.5 2.5 0 01-1.093 1.093c-.315.16-.643.22-.972.247-.312.025-.691.025-1.13.025H5.133c-.439 0-.818 0-1.13-.025-.33-.027-.657-.087-.972-.247a2.5 2.5 0 01-1.093-1.093c-.16-.315-.22-.643-.247-.972a14.916 14.916 0 01-.025-1.13V8.803v-.082c0-.397-.001-.747.092-1.08a2.5 2.5 0 01.398-.814c.206-.278.482-.493.796-.736l.065-.05L8.67 1.645l.062-.05c.222-.174.501-.393.832-.482zm.43 1.62a6.06 6.06 0 00-.301.227L4.041 7.357c-.415.323-.492.392-.544.463a.833.833 0 00-.133.27c-.023.085-.03.188-.03.714v6.03c0 .48 0 .79.02 1.026.018.227.05.31.07.352.08.156.207.284.364.364.042.02.125.052.352.07.236.02.546.02 1.027.02h9.666c.48 0 .791 0 1.027-.02.227-.018.31-.05.352-.07a.833.833 0 00.364-.364c.02-.042.052-.125.07-.352.02-.236.02-.546.02-1.027V8.804c0-.526-.007-.63-.03-.714a.834.834 0 00-.133-.27c-.052-.07-.13-.14-.544-.463l-5.652-4.396a5.99 5.99 0 00-.307-.23.884.884 0 00-.006.004zm-3.43 8.126a.833.833 0 011.015.599 2.501 2.501 0 004.842 0 .833.833 0 011.614.415 4.168 4.168 0 01-8.07 0 .833.833 0 01.599-1.014z"
        clipRule="evenodd"></path>
    </svg>
  );
};

export default HomeSmile;
